/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      country
      order
      createdAt
      updatedAt
    }
  }
`;
export const getFbPage = /* GraphQL */ `
  query GetFbPage($id: ID!) {
    getFbPage(id: $id) {
      id
      slug
      country
      name
      description
      image
      url
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
      aggregateRating {
        ratingValue
        ratingCount
      }
      priceRange
      telephone
      affiliation {
        name
      }
      categories
      createdAt
      updatedAt
      fbVideos {
        items {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFbPages = /* GraphQL */ `
  query ListFbPages(
    $filter: ModelFbPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFbPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        country
        name
        description
        image
        url
        address {
          streetAddress
          addressLocality
          addressRegion
          postalCode
        }
        aggregateRating {
          ratingValue
          ratingCount
        }
        priceRange
        telephone
        affiliation {
          name
        }
        categories
        createdAt
        updatedAt
        fbVideos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const fbPagesBySlug = /* GraphQL */ `
  query FbPagesBySlug(
    $slug: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFbPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbPagesBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        country
        name
        description
        image
        url
        address {
          streetAddress
          addressLocality
          addressRegion
          postalCode
        }
        aggregateRating {
          ratingValue
          ratingCount
        }
        priceRange
        telephone
        affiliation {
          name
        }
        categories
        createdAt
        updatedAt
        fbVideos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const fbPagesByCountry = /* GraphQL */ `
  query FbPagesByCountry(
    $country: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbPagesByCountry(
      country: $country
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        country
        name
        description
        image
        url
        address {
          streetAddress
          addressLocality
          addressRegion
          postalCode
        }
        aggregateRating {
          ratingValue
          ratingCount
        }
        priceRange
        telephone
        affiliation {
          name
        }
        categories
        createdAt
        updatedAt
        fbVideos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listFbVideos = /* GraphQL */ `
  query ListFbVideos(
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFbVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFbVideo = /* GraphQL */ `
  query GetFbVideo($id: ID!) {
    getFbVideo(id: $id) {
      id
      fbPageId
      country
      name
      description
      thumbnailUrl
      duration
      uploadDate
      contentUrl
      interactionCount
      publication {
        isLiveBroadcast
        startDate
        endDate
      }
      url
      datePublished
      width
      height
      thumbnail {
        contentUrl
        width
        height
      }
      headline
      isFamilyFriendly
      genre
      keywords
      commentCount
      comment {
        text
        upvoteCount
      }
      inLanguage
      contentSize
      videoQuality
      author {
        name
        url
      }
      publisher {
        logo {
          url
        }
        name
        url
      }
      about
      transcript
      videoMimeType
      embedUrl
      live
      status
      categories
      derivedCategories
      actualCategories
      createdAt
      updatedAt
    }
  }
`;
export const fbVideosByFbPage = /* GraphQL */ `
  query FbVideosByFbPage(
    $fbPageId: ID
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideosByFbPage(
      fbPageId: $fbPageId
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fbVideosByCountry = /* GraphQL */ `
  query FbVideosByCountry(
    $country: String
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideosByCountry(
      country: $country
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fbVideosByCountryByStatus = /* GraphQL */ `
  query FbVideosByCountryByStatus(
    $country: String
    $statusDatePublished: ModelFbVideoFbVideosByCountryByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideosByCountryByStatus(
      country: $country
      statusDatePublished: $statusDatePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFbVideoCategoryJoin = /* GraphQL */ `
  query GetFbVideoCategoryJoin($id: ID!) {
    getFbVideoCategoryJoin(id: $id) {
      id
      fbVideoId
      categoryId
      country
      countryCategoryId
      datePublished
      status
      createdAt
      updatedAt
      category {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      fbVideo {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFbVideoCategoryJoins = /* GraphQL */ `
  query ListFbVideoCategoryJoins(
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFbVideoCategoryJoins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideoId
        categoryId
        country
        countryCategoryId
        datePublished
        status
        createdAt
        updatedAt
        category {
          id
          name
          country
          order
          createdAt
          updatedAt
        }
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const fbVideoCategoryJoinsByCategory = /* GraphQL */ `
  query FbVideoCategoryJoinsByCategory(
    $categoryId: ID
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideoCategoryJoinsByCategory(
      categoryId: $categoryId
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideoId
        categoryId
        country
        countryCategoryId
        datePublished
        status
        createdAt
        updatedAt
        category {
          id
          name
          country
          order
          createdAt
          updatedAt
        }
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const fbVideoCategoryJoinsByFbVideo = /* GraphQL */ `
  query FbVideoCategoryJoinsByFbVideo(
    $fbVideoId: ID
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideoCategoryJoinsByFbVideo(
      fbVideoId: $fbVideoId
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideoId
        categoryId
        country
        countryCategoryId
        datePublished
        status
        createdAt
        updatedAt
        category {
          id
          name
          country
          order
          createdAt
          updatedAt
        }
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const fbVideoCategoryJoinsByCountryCategory = /* GraphQL */ `
  query FbVideoCategoryJoinsByCountryCategory(
    $countryCategoryId: String
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideoCategoryJoinsByCountryCategory(
      countryCategoryId: $countryCategoryId
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideoId
        categoryId
        country
        countryCategoryId
        datePublished
        status
        createdAt
        updatedAt
        category {
          id
          name
          country
          order
          createdAt
          updatedAt
        }
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const fbVideoCategoryJoinsByCountryCategoryByStatus = /* GraphQL */ `
  query FbVideoCategoryJoinsByCountryCategoryByStatus(
    $countryCategoryId: String
    $statusDatePublished: ModelFbVideoCategoryJoinFbVideoCategoryJoinsByCountryCategoryByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideoCategoryJoinsByCountryCategoryByStatus(
      countryCategoryId: $countryCategoryId
      statusDatePublished: $statusDatePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideoId
        categoryId
        country
        countryCategoryId
        datePublished
        status
        createdAt
        updatedAt
        category {
          id
          name
          country
          order
          createdAt
          updatedAt
        }
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTgMessage = /* GraphQL */ `
  query GetTgMessage($id: ID!) {
    getTgMessage(id: $id) {
      id
      message_id
      sender_chat {
        id
        title
        username
        type
      }
      chat {
        id
        title
        username
        type
      }
      date
      text
      entities {
        offset
        length
        type
      }
      fbVideoId
      createdAt
      updatedAt
    }
  }
`;
export const listTgMessages = /* GraphQL */ `
  query ListTgMessages(
    $filter: ModelTgMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTgMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message_id
        sender_chat {
          id
          title
          username
          type
        }
        chat {
          id
          title
          username
          type
        }
        date
        text
        entities {
          offset
          length
          type
        }
        fbVideoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tgMessagesByFbVideo = /* GraphQL */ `
  query TgMessagesByFbVideo(
    $fbVideoId: ID
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTgMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tgMessagesByFbVideo(
      fbVideoId: $fbVideoId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message_id
        sender_chat {
          id
          title
          username
          type
        }
        chat {
          id
          title
          username
          type
        }
        date
        text
        entities {
          offset
          length
          type
        }
        fbVideoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
