import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <TextInput source="country" />
      <NumberInput source="order" />
    </SimpleForm>
  </Create>
);
