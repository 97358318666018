import React from 'react';
import { Admin, Resource, ListGuesser, ShowGuesser } from 'react-admin';
import {
  AmplifyAuthProvider,
  useAuthProvider,
  useAmplifyDataProvider,
  reducers,
} from '../ra-aws-amplify';
import config from '../aws-exports';
import schema from '../graphql/schema.json';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import LoginPage from './admin/LoginPage';
import CategoryList from './admin/CategoryList';
import CategoryCreate from './admin/CategoryCreate';
import CategoryEdit from './admin/CategoryEdit';
import CategoryShow from './admin/CategoryShow';
import FbPageList from './admin/FbPageList';
import FbPageCreate from './admin/FbPageCreate';
import FbPageEdit from './admin/FbPageEdit';
import FbVideoList from './admin/FbVideoList';

export default () => {
  const authProvider = useAuthProvider();
  const dataProvider = useAmplifyDataProvider({
    config,
    schema,
    queries,
    mutations,
  });

  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <AmplifyAuthProvider>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        customReducers={reducers}
        loginPage={LoginPage}
      >
        <Resource
          name="Category"
          list={CategoryList}
          create={CategoryCreate}
          edit={CategoryEdit}
          show={CategoryShow}
        />
        <Resource
          name="FbPage"
          list={FbPageList}
          // create={FbPageCreate}
          edit={FbPageEdit}
          show={ShowGuesser}
        />
        <Resource
          name="FbVideo"
          list={FbVideoList}
          // create={LiveStreamCreate}
          // edit={EditGuesser}
          show={ShowGuesser}
        />
      </Admin>
    </AmplifyAuthProvider>
  );
};
