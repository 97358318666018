import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  EditButton,
  ShowButton,
} from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';

export default (props) => {
  const [filter, setFilter] = useState(null);

  return (
    <List
      {...props}
      actions={<ListActions showCreate={false} />}
      pagination={<AmplifyPagination onNext={(filter) => setFilter(filter)} />}
      filter={filter}
      perPage={1000}
    >
      <Datagrid rowClick="show">
        <TextField disabled label="Id" source="id" />
        <TextField source="slug" />
        <TextField source="name" />
        <TextField source="country" />
        <UrlField source="url" />
        <ReferenceArrayField reference="Category" source="categories">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
