import React from 'react';
// import './App.css';
import Admin from './components/Admin';

import Amplify from '@aws-amplify/core';
import config from './aws-exports';

const domain = 'auth.tgi.live';
const redirectUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://a.tgi.live/'
    : 'http://localhost:3000/';

Amplify.configure({
  ...config,
  oauth: {
    ...config.oauth,
    domain,
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
  },
});

function App() {
  return <Admin />;
}

export default App;
