/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      country
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      country
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      country
      order
      createdAt
      updatedAt
    }
  }
`;
export const createFbPage = /* GraphQL */ `
  mutation CreateFbPage(
    $input: CreateFbPageInput!
    $condition: ModelFbPageConditionInput
  ) {
    createFbPage(input: $input, condition: $condition) {
      id
      slug
      country
      name
      description
      image
      url
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
      aggregateRating {
        ratingValue
        ratingCount
      }
      priceRange
      telephone
      affiliation {
        name
      }
      categories
      createdAt
      updatedAt
      fbVideos {
        items {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFbPage = /* GraphQL */ `
  mutation UpdateFbPage(
    $input: UpdateFbPageInput!
    $condition: ModelFbPageConditionInput
  ) {
    updateFbPage(input: $input, condition: $condition) {
      id
      slug
      country
      name
      description
      image
      url
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
      aggregateRating {
        ratingValue
        ratingCount
      }
      priceRange
      telephone
      affiliation {
        name
      }
      categories
      createdAt
      updatedAt
      fbVideos {
        items {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFbPage = /* GraphQL */ `
  mutation DeleteFbPage(
    $input: DeleteFbPageInput!
    $condition: ModelFbPageConditionInput
  ) {
    deleteFbPage(input: $input, condition: $condition) {
      id
      slug
      country
      name
      description
      image
      url
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
      aggregateRating {
        ratingValue
        ratingCount
      }
      priceRange
      telephone
      affiliation {
        name
      }
      categories
      createdAt
      updatedAt
      fbVideos {
        items {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          url
          datePublished
          width
          height
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          inLanguage
          contentSize
          videoQuality
          about
          transcript
          videoMimeType
          embedUrl
          live
          status
          categories
          derivedCategories
          actualCategories
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFbVideo = /* GraphQL */ `
  mutation CreateFbVideo(
    $input: CreateFbVideoInput!
    $condition: ModelFbVideoConditionInput
  ) {
    createFbVideo(input: $input, condition: $condition) {
      id
      fbPageId
      country
      name
      description
      thumbnailUrl
      duration
      uploadDate
      contentUrl
      interactionCount
      publication {
        isLiveBroadcast
        startDate
        endDate
      }
      url
      datePublished
      width
      height
      thumbnail {
        contentUrl
        width
        height
      }
      headline
      isFamilyFriendly
      genre
      keywords
      commentCount
      comment {
        text
        upvoteCount
      }
      inLanguage
      contentSize
      videoQuality
      author {
        name
        url
      }
      publisher {
        logo {
          url
        }
        name
        url
      }
      about
      transcript
      videoMimeType
      embedUrl
      live
      status
      categories
      derivedCategories
      actualCategories
      createdAt
      updatedAt
    }
  }
`;
export const updateFbVideo = /* GraphQL */ `
  mutation UpdateFbVideo(
    $input: UpdateFbVideoInput!
    $condition: ModelFbVideoConditionInput
  ) {
    updateFbVideo(input: $input, condition: $condition) {
      id
      fbPageId
      country
      name
      description
      thumbnailUrl
      duration
      uploadDate
      contentUrl
      interactionCount
      publication {
        isLiveBroadcast
        startDate
        endDate
      }
      url
      datePublished
      width
      height
      thumbnail {
        contentUrl
        width
        height
      }
      headline
      isFamilyFriendly
      genre
      keywords
      commentCount
      comment {
        text
        upvoteCount
      }
      inLanguage
      contentSize
      videoQuality
      author {
        name
        url
      }
      publisher {
        logo {
          url
        }
        name
        url
      }
      about
      transcript
      videoMimeType
      embedUrl
      live
      status
      categories
      derivedCategories
      actualCategories
      createdAt
      updatedAt
    }
  }
`;
export const deleteFbVideo = /* GraphQL */ `
  mutation DeleteFbVideo(
    $input: DeleteFbVideoInput!
    $condition: ModelFbVideoConditionInput
  ) {
    deleteFbVideo(input: $input, condition: $condition) {
      id
      fbPageId
      country
      name
      description
      thumbnailUrl
      duration
      uploadDate
      contentUrl
      interactionCount
      publication {
        isLiveBroadcast
        startDate
        endDate
      }
      url
      datePublished
      width
      height
      thumbnail {
        contentUrl
        width
        height
      }
      headline
      isFamilyFriendly
      genre
      keywords
      commentCount
      comment {
        text
        upvoteCount
      }
      inLanguage
      contentSize
      videoQuality
      author {
        name
        url
      }
      publisher {
        logo {
          url
        }
        name
        url
      }
      about
      transcript
      videoMimeType
      embedUrl
      live
      status
      categories
      derivedCategories
      actualCategories
      createdAt
      updatedAt
    }
  }
`;
export const createFbVideoCategoryJoin = /* GraphQL */ `
  mutation CreateFbVideoCategoryJoin(
    $input: CreateFbVideoCategoryJoinInput!
    $condition: ModelFbVideoCategoryJoinConditionInput
  ) {
    createFbVideoCategoryJoin(input: $input, condition: $condition) {
      id
      fbVideoId
      categoryId
      country
      countryCategoryId
      datePublished
      status
      createdAt
      updatedAt
      category {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      fbVideo {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFbVideoCategoryJoin = /* GraphQL */ `
  mutation UpdateFbVideoCategoryJoin(
    $input: UpdateFbVideoCategoryJoinInput!
    $condition: ModelFbVideoCategoryJoinConditionInput
  ) {
    updateFbVideoCategoryJoin(input: $input, condition: $condition) {
      id
      fbVideoId
      categoryId
      country
      countryCategoryId
      datePublished
      status
      createdAt
      updatedAt
      category {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      fbVideo {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFbVideoCategoryJoin = /* GraphQL */ `
  mutation DeleteFbVideoCategoryJoin(
    $input: DeleteFbVideoCategoryJoinInput!
    $condition: ModelFbVideoCategoryJoinConditionInput
  ) {
    deleteFbVideoCategoryJoin(input: $input, condition: $condition) {
      id
      fbVideoId
      categoryId
      country
      countryCategoryId
      datePublished
      status
      createdAt
      updatedAt
      category {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      fbVideo {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          name
          url
        }
        about
        transcript
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTgMessage = /* GraphQL */ `
  mutation CreateTgMessage(
    $input: CreateTgMessageInput!
    $condition: ModelTgMessageConditionInput
  ) {
    createTgMessage(input: $input, condition: $condition) {
      id
      message_id
      sender_chat {
        id
        title
        username
        type
      }
      chat {
        id
        title
        username
        type
      }
      date
      text
      entities {
        offset
        length
        type
      }
      fbVideoId
      createdAt
      updatedAt
    }
  }
`;
export const updateTgMessage = /* GraphQL */ `
  mutation UpdateTgMessage(
    $input: UpdateTgMessageInput!
    $condition: ModelTgMessageConditionInput
  ) {
    updateTgMessage(input: $input, condition: $condition) {
      id
      message_id
      sender_chat {
        id
        title
        username
        type
      }
      chat {
        id
        title
        username
        type
      }
      date
      text
      entities {
        offset
        length
        type
      }
      fbVideoId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTgMessage = /* GraphQL */ `
  mutation DeleteTgMessage(
    $input: DeleteTgMessageInput!
    $condition: ModelTgMessageConditionInput
  ) {
    deleteTgMessage(input: $input, condition: $condition) {
      id
      message_id
      sender_chat {
        id
        title
        username
        type
      }
      chat {
        id
        title
        username
        type
      }
      date
      text
      entities {
        offset
        length
        type
      }
      fbVideoId
      createdAt
      updatedAt
    }
  }
`;
