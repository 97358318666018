import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
} from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceArrayInput reference="Category" source="categories" label="Categories">
        <SelectArrayInput>
          <ChipField source="name" />
        </SelectArrayInput>
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
