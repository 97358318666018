import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="country" />
      <NumberField source="order" />
    </SimpleShowLayout>
  </Show>
);
