import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  ShowButton,
} from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';

export default (props) => {
  const [filter, setFilter] = useState(null);

  return (
    <List
      {...props}
      actions={<ListActions showCreate={true} />}
      pagination={<AmplifyPagination onNext={(filter) => setFilter(filter)} />}
      filter={filter}
      perPage={100}
    >
      <Datagrid rowClick="show">
        <TextField disabled label="Id" source="id" />
        <TextField source="name" />
        <TextField source="country" />
        <NumberField source="order" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
