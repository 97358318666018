import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <TextInput source="country" />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);
